/**
 * Mixing used ofr common DPAE methods and data.
 */
import { store } from '@/store';
import { DPAE_STATUS_MENU, USER_STATUS_MENU, USER_STATUS_DRAFT, USER_STATUS_ACTIVE, USER_STATUS_DISABLE, DPAE_STATUS } from '@/shared/constants';

export default {
	name: 'dpaeMixin',
	data() {
		return {};
	},
	computed: {
		useDpae() {
			return store.getCurrentProjectConfig().useDpae;
		},
		getStatusMenu() {
			const userStatusMenu = this.FormMenu(USER_STATUS_MENU).map((status) => {
				switch (status.value) {
					case USER_STATUS_DRAFT:
						return { ...status, colorClass: 'bgc-grey-light', selectColorClass: 'text-grey-dark bgc-grey-dark' };
					case USER_STATUS_ACTIVE:
						return { ...status, colorClass: 'bgc-green-light', selectColorClass: 'text-secondary bg-secondary' };
					case USER_STATUS_DISABLE:
						return { ...status, colorClass: 'bgc-red-light', selectColorClass: 'text-danger bg-danger' };

					default:
						return { ...status, colorClass: '' };
				}
			});
			return userStatusMenu;
		},
		getDpaeStatusMenu() {
			const dpaeStatusMenu = this.FormMenu(DPAE_STATUS_MENU).map((status) => {
				switch (status.value) {
					case 0:
						return { ...status, colorClass: '', icon: 'Send' };
					case 1:
						return { ...status, colorClass: 'text-grey-dark', icon: '' };
					case 2:
						return { ...status, colorClass: 'text-warning', icon: 'Clock3' };
					case 3:
						return { ...status, colorClass: 'text-secondary', icon: 'Check' };
					case 4:
						return { ...status, colorClass: 'text-danger', icon: 'X' };

					default:
						return { ...status, colorClass: '', icon: '' };
				}
			});
			return dpaeStatusMenu;
		}
	},
	methods: {
		getDpaeStatusIcon(status) {
			const currentItem = this.getDpaeStatusMenu.find((x) => x.value === status);
			if (currentItem) return currentItem.icon;
			return this.getDpaeStatusMenu[1].icon;
		},
		getDpaeStatusClass(status) {
			const currentItem = this.getDpaeStatusMenu.find((x) => x.value === status);
			if (currentItem) return currentItem.colorClass;
			return this.getDpaeStatusMenu[0].colorClass;
		},
		getDpaeStatusText(status) {
			const currentItem = this.getDpaeStatusMenu.find((x) => x.value === status);
			if (currentItem) return currentItem.text;
			return this.getDpaeStatusMenu[1].text;
		},
		isSendableStatusDpae(status) {
			return [DPAE_STATUS.NOT_SENT, DPAE_STATUS.FAILED, DPAE_STATUS.CONFIRMED, DPAE_STATUS.PROCESSING].includes(status);
		},
		checkIfThereIsInvalidFieldsInUser(user) {
			return user.dpaeInvalidFields.userInvalidFields.length > 0;
		},
		checkIfThereIsInvalidFieldsInLegalEntity(user) {
			return user.dpaeInvalidFields.legalEntityInvalidFields.length > 0;
		},
		checkIfThereIsInvalidFields(user) {
			return this.checkIfThereIsInvalidFieldsInUser(user) || this.checkIfThereIsInvalidFieldsInLegalEntity(user);
		},
		convertStringToArray(input) {
			return input
				.split(',')
				.map((item) => item.trim())
				.filter((item) => item.length > 0);
		},
		getUserInvalidFields(missingFields) {
			let userError = this.convertStringToArray(missingFields);

			if (userError.length > 0) {
				return userError
					.map(
						(field) => `<div class="d-flex flex-row align-items-center gap-2 pl-3 py-1">
							<div style="width: 5px; height: 5px; border-radius: 50%; background-color: black;"></div>
							<div>${field}</div>
						</div>`
					)
					.join('');
			}
		},
		getLegalEntityInvalidFields(missingFields) {
			let legalEntityError = this.convertStringToArray(missingFields);

			if (legalEntityError.length > 0) {
				return legalEntityError
					.map(
						(field) => `<div class="d-flex flex-row align-items-center gap-2 pl-3 py-1">
							<div style="width: 5px; height: 5px; border-radius: 50%; background-color: black;"></div>
							<div>${field}</div>
						</div>`
					)
					.join('');
			}

			return '';
		},
		formatInvalidFields(errorList) {
			let formattedErrors = {
				LegalEntity: [],
				User: []
			};

			errorList.forEach((field) => {
				if (field.startsWith('legalEntity')) {
					formattedErrors.LegalEntity.push(field.replace('legalEntity', '').trim());
				} else if (field.startsWith('user')) {
					formattedErrors.User.push(field.replace('user', '').trim());
				}
			});

			let formattedString = '';

			if (formattedErrors.LegalEntity.length > 0) {
				formattedString += 'Legal entity:\n';
				formattedString += formattedErrors.LegalEntity.join('\n') + '\n';
			}

			if (formattedErrors.User.length > 0) {
				formattedString += 'User:\n';
				formattedString += formattedErrors.User.join('\n');
			}

			return formattedString;
		}
	}
};
